import { faCapsules, faHome, faMedkit, faNotesMedical, faSyringe } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useContext } from 'react'
import { FieldContext } from '../../../../allContext'
import Logo from '../../../../assets/logo/Logo192.png'
import classes from './MobileNav.module.css'

export default function MobileNav() {
    const { state, dispatch } = useContext(FieldContext)

    return (
        <div className={classes.mobileNav}>
            <div className={classes.navigation}>
                <ul>
                    <li
                        onClick={() => dispatch({ type: 'chief' })}
                        className={state.field === 'chief' ? `${classes.list} ${classes.active}` : classes.list}>
                        <span className={classes.icon}>
                            <FontAwesomeIcon icon={faMedkit} />
                        </span>
                        <span className={classes.text}>C/C</span>
                    </li>
                    <li
                        onClick={() => dispatch({ type: 'investigation' })}
                        className={
                            state.field === 'investigation' ? `${classes.list} ${classes.active}` : classes.list
                        }>
                        <span className={classes.icon}>
                            <FontAwesomeIcon icon={faSyringe} />
                        </span>
                        <span className={classes.text}>Inv</span>
                    </li>
                    <li className={`${classes.list} ${classes.active}`}>
                        <span className={classes.icon}>
                            <FontAwesomeIcon icon={faHome} style={{ display: 'none' }} />
                            <img src={Logo} alt="" />
                        </span>
                        {/* <span className={classes.text}>Home</span> */}
                    </li>
                    <li
                        onClick={() => dispatch({ type: 'medicine' })}
                        className={state.field === 'medicine' ? `${classes.list} ${classes.active}` : classes.list}>
                        <span className={classes.icon}>
                            <FontAwesomeIcon icon={faCapsules} />
                        </span>
                        <span className={classes.text}>Rx</span>
                    </li>
                    <li
                        onClick={() => dispatch({ type: 'advice' })}
                        className={state.field === 'advice' ? `${classes.list} ${classes.active}` : classes.list}>
                        <span className={classes.icon}>
                            <FontAwesomeIcon icon={faNotesMedical} />
                        </span>
                        <span className={classes.text}>Adv</span>
                    </li>
                    <div className={classes.indicator}></div>
                </ul>
            </div>
        </div>
    )
}
