import Landing from '../components/Landing/Landing'

const LandingPage = () => {
    return (
        <div>
            <Landing />
        </div>
    )
}

export default LandingPage
