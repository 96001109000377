import classes from './PatientSearch.module.css'

const PatientSearch = ({ arr, setPatient, setPhone, setSearchHide }) => {
    return (
        <div className={classes.PatientSearch}>
            <ul>
                {arr.map(
                    (v, i) =>
                        v.role_name !== 'admin' &&
                        v.role_name !== 'doctor' && (
                            <li
                                key={i}
                                onClick={async () => {
                                    await setPatient({ type: 'input', payload: v })
                                    setPhone(v.phone)
                                    setSearchHide(false)
                                }}>
                                {v.name} <span>{v.phone}</span>
                            </li>
                        )
                )}
            </ul>
        </div>
    )
}

export default PatientSearch
