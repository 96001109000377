import { useState, useEffect } from 'react'
import logo from '../../../../assets/img/logo.png'

export default function Header({ classes, doc_id, apiV1 }) {
    const [headerData, setHeaderData] = useState([])
    const [details, setDetails] = useState({})

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${apiV1}/ep/doctor-ep-header/${doc_id}`, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            })

            const data = await response.json()
            if (response.ok) {
                setHeaderData(data)
            }
        }
        try {
            fetchData()
        } catch {
            setHeaderData([])
        }
    }, [apiV1, doc_id])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${apiV1}/doctors/detail/${doc_id}`, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })

                const data = await response.json()

                if (response.ok) {
                    setDetails(data)
                }
            } catch {
                setDetails({})
            }
        }
        fetchData()
    }, [apiV1, doc_id])

    let leftHeader = headerData?.filter((v) => v.header_side === 'left')
    let rightHeader = headerData?.filter((v) => v.header_side === 'right')

    return (
        <div className={classes.top}>
            <div className={classes.topLeft}>
                {leftHeader.length > 0 || rightHeader.length > 0 ? (
                    <>
                        <h3>{leftHeader[0]?.heading}</h3>
                        {leftHeader[0]?.body.split('\n')?.map((v, i) => {
                            return <p key={i}>{v}</p>
                        })}
                    </>
                ) : (
                    <>
                        <h3>
                            {details?.doctor?.dr_title || ''} {details?.user?.name}
                        </h3>
                        {details?.qualifications?.map((qual, i) => (
                            <p key={i}>{qual.qualification || ''}</p>
                        ))}
                        {details?.specialities?.map((spec, i) => (
                            <p key={i}>{spec.speciality || ''}</p>
                        ))}
                    </>
                )}
            </div>
            <div className={classes.topRight}>
                {leftHeader.length > 0 || rightHeader.length > 0 ? (
                    <>
                        <h3>{rightHeader[0]?.heading}</h3>
                        {rightHeader[0]?.body.split('\n')?.map((v, i) => {
                            return <p key={i}>{v}</p>
                        })}
                    </>
                ) : (
                    <div className={classes.logo}>
                        <img src={logo} alt="" />
                    </div>
                )}
            </div>
        </div>
    )
}
